<template>
  <div :class="['grid', col ? 'grid--col-' + col : ' ']" :id="id" name="grid">
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    col: String,
  },
  methods:{
    responsiveClasses: function() {
      let id = this.id;
      let div = document.getElementById(id);
      if(div !== null){
        let name = div.getAttribute('name');
        let options = [{prop : 'visibility', name : name},
                       {prop : 'col', name : name},];
        options.forEach(this.classNames)
      }
    },
  },
  beforeMount(){
    this.id = this._uid
  },
  mounted(){
    this.responsiveClasses()
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/breakpoints.scss';

.grid{
	width:100%;
	@include breakpoint(sm){
		grid-template-columns:repeat(12, 1fr);
		grid-gap: var(--grid-gutters);
		display:grid;
		grid-template-rows: auto;
	}
}
.grid--col-auto{
  grid-template-columns: repeat(auto-fit,minmax(50px,1fr));
}
.grid.grid--col-1{
  .grid__container{
    grid-column-end: span 12;
  }
}
.grid.grid--col-2{
  .grid__container{
    grid-column-end: span 6;
  }
}
.grid.grid--col-3{
  .grid__container{
    grid-column-end: span 4;
  }
}
.grid.grid--col-4{
  .grid__container{
    grid-column-end: span 3;
  }
}

// Responsive Design - Column Variations
.grid.grid--col-sm-auto{
	@include breakpoint(sm){
		grid-template-columns: repeat(auto-fit,minmax(50px,1fr));
		.grid__container{
			grid-column-end: initial;
		}
	}
}
.grid.grid--col-sm-1{
	.grid__container{
		@include breakpoint(sm){
			grid-column-end: span 12;
		}
	}
}
.grid.grid--col-sm-2{
	.grid__container{
		@include breakpoint(sm){
			grid-column-end: span 6;
		}
	}
}
.grid.grid--col-sm-3{
	.grid__container{
		@include breakpoint(sm){
			grid-column-end: span 4;
		}
	}
}
.grid.grid--col-sm-4{
	.grid__container{
		@include breakpoint(sm){
			grid-column-end: span 3;
		}
	}
}
.grid.grid--col-md-auto{
	@include breakpoint(md){
		grid-template-columns: repeat(auto-fit,minmax(50px,1fr));
		.grid__container{
			grid-column-end: initial;
		}
	}
}
.grid.grid--col-md-1{
	.grid__container{
		@include breakpoint(md){
			grid-column-end: span 12;
		}
	}
}
.grid.grid--col-md-2{
	.grid__container{
		@include breakpoint(md){
			grid-column-end: span 6;
		}
	}
}
.grid.grid--col-md-3{
	.grid__container{
		@include breakpoint(md){
			grid-column-end: span 4;
		}
	}
}
.grid.grid--col-md-4{
	.grid__container{
		@include breakpoint(md){
			grid-column-end: span 3;
		}
	}
}
.grid.grid--col-lg-auto{
	@include breakpoint(lg){
		grid-template-columns: repeat(auto-fit,minmax(50px,1fr));
		.grid__container{
			grid-column-end: initial;
		}
	}
}
.grid.grid--col-lg-1{
	.grid__container{
		@include breakpoint(lg){
			grid-column-end: span 12;
		}
	}
}
.grid.grid--col-lg-2{
	.grid__container{
		@include breakpoint(lg){
			grid-column-end: span 6;
		}
	}
}
.grid.grid--col-lg-3{
	.grid__container{
		@include breakpoint(lg){
			grid-column-end: span 4;
		}
	}
}
.grid.grid--col-lg-4{
	.grid__container{
		@include breakpoint(lg){
			grid-column-end: span 3;
		}
	}
}

.grid.grid--col-xl-auto{
	@include breakpoint(xl){
		grid-template-columns: repeat(auto-fit,minmax(50px,1fr));
		.grid__container{
			grid-column-end: initial;
		}
	}
}
.grid.grid--col-xl-1{
	.grid__container{
		@include breakpoint(xl){
			grid-column-end: span 12;
		}
	}
}
.grid.grid--col-xl-2{
	.grid__container{
		@include breakpoint(xl){
			grid-column-end: span 6;
		}
	}
}
.grid.grid--col-xl-3{
	.grid__container{
		@include breakpoint(xl){
			grid-column-end: span 4;
		}
	}
}
.grid.grid--col-xl-4{
	.grid__container{
		@include breakpoint(xl){
			grid-column-end: span 3;
		}
	}
}
</style>